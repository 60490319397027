import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Button from '../components/Button/Button'
import {Container} from 'react-bootstrap'
import '../styles/pages/success.scss'

export default () => {
    return (
        <Layout>
            <Seo title={'Форма успешно отправлена!'}/>
            <Container className='content' fluid="xl">
                <section className="success">
                    <h1>Ваша заявка успешно отправлена!</h1>
                    <p>Наш специалист свяжется с вами в ближайшее время. Если вы не получили ответ в течение рабочего дня, пожалуйста, отправьте форму повторно.</p>
                    <Button 
                        as='link' 
                        text='Вернуться на сайт'
                        linkTo = ''
                    />
                </section>
            </Container>
        </Layout>   
    )
}